
export const CloseTypes = {
    top: 'top',
    bottom: 'bottom',
    both: 'both'
};

export default function (md: any) {
    md.core.ruler.push('collapsible', (state: any) => {
        const COLLAPSIBLE_OPEN_REGEX = /\[\[collapsible\s*(.*)\s*]]/;
        const COLLAPSIBLE_CLOSE_REGEX = /\[\[\/collapsible]]/;
        const COLLAPSIBLE_OPTION_HIDE = 'hide="';
        const COLLAPSIBLE_OPTION_SHOW = 'show="';

        let closeTexts: Array<String> = []; // for invested collapsible blocks
        let closeTypes: Array<String> = []; // for invested collapsible blocks

        const tokens = state.tokens;
        let i = 0;

        while (i < tokens.length) {
            const openToken = tokens[i];
            const token = tokens[i+1];
            let matchOpen, closeType = CloseTypes.top;

            if (openToken.type === 'paragraph_open' &&
                (matchOpen = token.content.match(COLLAPSIBLE_OPEN_REGEX))
            ) {
                const collapsible = new state.Token('collapsible_open', 'div', 0);
                const collapsibleOptions = matchOpen[1].split(/" /);

                //Initial values
                collapsible.content = {openText: '', closeText: '', closeType: ''};

                collapsibleOptions.forEach((option: string) => {
                    if (option.endsWith('"')) {
                        option = option.slice(0, -1);
                    }

                    if (option.startsWith(COLLAPSIBLE_OPTION_HIDE)) {
                        collapsible.content.closeText = option.substr(COLLAPSIBLE_OPTION_HIDE.length);
                    } else if (option.startsWith(COLLAPSIBLE_OPTION_SHOW)) {
                        collapsible.content.openText = option.substr(COLLAPSIBLE_OPTION_SHOW.length);
                    } else if (option.startsWith('hideLocation="both')) {
                        closeType = CloseTypes.both;
                    } else if (option.startsWith('hideLocation="bottom')) {
                        closeType = CloseTypes.bottom;
                    }
                });

                collapsible.content.closeType = closeType;
                closeTypes.push(closeType);
                closeTexts.push(collapsible.content.closeText);

                tokens.splice(i, 3, collapsible);
            }
            
            if (openToken.type === 'paragraph_open' &&
                token.content.match(COLLAPSIBLE_CLOSE_REGEX)
            ) {
                const collapsible = new state.Token('collapsible_close', 'div', 0);
                collapsible.content = {closeType: closeTypes.pop(), closeText: closeTexts.pop()};

                tokens.splice(i, 3, collapsible);
            }

            i++;
        }
    });
};
