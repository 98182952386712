
const SEPARATOR = '---|'

function getTableHead(cells: Array<string>) {
    // String after tableHead
    let headSeparator = '\n|' + SEPARATOR.repeat(cells.length + 1) + '\n';
    let head = '|', firstRow = '';

    if (cells[0].startsWith('~')) {
        cells.forEach((cell) => {
            head += cell.replace(/~\s/, '') + '|';
        });
    } else {
        head = head.repeat(cells.length + 1);
        firstRow = cells.reduce((prevCells, cell) => (prevCells += `${cell}|`), '|') + '\n';
    }

    return '\n' + head + headSeparator + firstRow;
}

function prepareTableRow(row: string, isFirst = false) {
    const cells: Array<string> = row
        .substring(2, row.lastIndexOf('||'))
        .split('||');

    if (isFirst) {
        return getTableHead(cells);
    }
    
    return cells.reduce((prevCells, cell) => (prevCells += `${cell}|`), '|') + '\n';
}
function isNextRowTable(row: string): boolean {
    return row.startsWith('||');
}

export default function (input: string) {
    const rows = input.split('\n');
    let preparedContent = '';
    let inTable = false;

    rows.forEach((row: string, index: number) => {
        if (inTable) {
            preparedContent += prepareTableRow(row);
            inTable = rows[index + 1] ? isNextRowTable(rows[index + 1]) : false;
        } else if (row.startsWith('||')) {
            preparedContent += prepareTableRow(row, true);
            inTable = rows[index + 1] ? isNextRowTable(rows[index + 1]) : false;
        } else {
            preparedContent += row + '\n';
        }
    });

    return preparedContent;
}
