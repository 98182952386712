import React from 'react';
import { sdk } from '../../utils';
import './Header.scss';


const Header: React.FC<{ history: any }> = ({ history }) => (
    <header className="header">
        <div className="header__content">
            <div>
                <a href="/">SCP Foundation</a>
            </div>
            <div>
            </div>
        </div>
        <div className="header__navigation">
            <div className="header__navigation-content">
                <span className="header__navigation-element">
                    <a
                    href="/random"
                    className="header__random-page-link"
                    onClick={(event) => {
                        if (event.ctrlKey || event.metaKey) {
                            return;
                        }
                        event.preventDefault();
                        sdk.getRandomWikidotPage().then(randomPageName => history.push(`/${randomPageName}`));
                    }}
                    >Random page</a>
                </span>
                <span className="header__navigation-element">
                    <a href="/scp-list">Series I</a>
                </span>
                <span className="header__navigation-element">
                    <a href="/scp-list-2">Series II</a>
                </span>
                <span className="header__navigation-element">
                    <a href="/scp-list-3">Series III</a>
                </span>
                <span className="header__navigation-element">
                    <a href="/scp-list-4">Series IV</a>
                </span>
                <span className="header__navigation-element">
                    <a href="/scp-list-5">Series V</a>
                </span>
                <span className="header__navigation-element">
                    <a href="/scp-list-ru">Series RU</a>
                </span>
                <span className="header__navigation-element">
                    <a href="/stories">Stories</a>
                </span>
                <span className="header__navigation-element">
                    <a href="/canon-hub">Canons</a>
                </span>
            </div>
        </div>
    </header>
);

export default Header;
