import React from 'react';
import './Page.scss';

interface WKPage {
    title: string;
    content: string;
    html: string;
    rating: number;
}

const Page: React.FC<{page: WKPage}> = ({page}) => {
    if (page) {
        return (
            <div className="page">
                <div className="page__title">
                    {page.title}
                    <div className="page__rating">
                        {page.rating > 0 ? `+${page.rating}` : page.rating}
                    </div>
                </div>
                <div className="page__content" dangerouslySetInnerHTML={{ __html: page.html }} />
            </div>
        );
    } else {
        return (
            <div className="page" />
        );
    }
};

export default Page;
