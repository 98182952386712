import ky from 'ky';

const WKDB_ENDPOINT = 'https://scpdb.org';
const API_ENDPOINT = `${WKDB_ENDPOINT}/api`;

export default {
    getWikidotPage: (wikiName: string, pageName: string) => {
      const url = `${API_ENDPOINT}/wikidot/pages/${wikiName.toLowerCase()}/${pageName.toLowerCase()}`;
      return ky.get(url).then(r => r.json());
    },

    getRandomWikidotPage: () => ky.get(`${API_ENDPOINT}/wikidot_random_page`)
      .then(r => r.json())
      .then(r => r.name),
}
