import React from 'react';
// import { sdk } from '../../utils';
import 'normalize.css';
import './App.scss';
import PageContainer from '../PageContainer/PageContainer';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import MainPage from '../../components/MainPage/MainPage';


export default class App extends React.Component<{ history: any, match: any }> {

  linkClickHandler?: EventListener;

  componentWillMount() {
    this.linkClickHandler = (event: any) => {
      if (event.ctrlKey || event.metaKey) {
        return;
      }

      if (event.target.tagName !== 'A' || event.target.classList.contains('header__random-page-link')) {
        return;
      }
      let current = event.target;
      let matched = false;
      while (current) {
          if (Array.from((current as any).classList).includes('page__content') ||
              Array.from((current as any).classList).includes('header__navigation')) {
              matched = true;
              break;
          }
          current = (current as any).parentElement;
      }
      if (matched && window.location.origin === event.target.origin) {
          const currentPage = event.target.origin + event.target.pathname + event.target.hash;

          if (event.target.hash !== '' && currentPage === event.target.href) {
            this.props.history.push(event.target.pathname + event.target.hash);
          } else {
            event.preventDefault();
            this.props.history.push(event.target.pathname);
            window.scrollTo(0, 0);
          }
      }
    };

    document.addEventListener('click', this.linkClickHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.linkClickHandler as EventListener);
  }

  renderContent() {
    if (this.props.match.params.pageName) {
      return <PageContainer pageName={this.props.match.params.pageName} />;
    } else {
      return <MainPage />;
    }
  }

  render() {
    return (
      <div className="app">
        <Header history={this.props.history} />
        {this.renderContent()}
        <Footer />
      </div>
    );
  }
}
