import './collapsible.scss';

export default function initCollapsibles(element: HTMLElement) {
    element.addEventListener('click', (event) => {
        const target: any = event.target;

        if (target && Array.from(target.classList).includes('collapsible__open')) {
            target.parentElement.classList.add('collapsible_opened');
        }

        if (target && Array.from(target.classList).includes('collapsible__close')) {
            target.parentElement.classList.remove('collapsible_opened');
        }
    });
}
