import { PageMeta } from '.';
import table from './plugins/table';

const imgBlockRegex = /\[\[include component:image-block\s+name=(.+?)\s*\|\s*caption=(.*?)\]\]/sgi;
const imgRightRegex = /\[\[div class="rimg"]]\s*\[\[image\s+(.+?)\s+width="(\d+?)"\s?]]\s*\[\[span]](.+?)\[\[\/span]]\s*\[\[\/div]]/sgi;
const imgCenterRegex = /\[\[div class="cimg"]]\s*\[\[image\s+(.+?)\s+width="(\d+?)"\s?]]\s*\[\[span]](.+?)\[\[\/span]]\s*\[\[\/div]]/sgi;
const imgLeftRegex = /\[\[div class="limg"]]\s*\[\[image\s+(.+?)\s+width="(\d+?)"\s?]]\s*\[\[span]](.+?)\[\[\/span]]\s*\[\[\/div]]/sgi;
const imgLinkedRightRegex = /\[\[div class="rimg"]]\s*\[\[image\s+(.+?)\s+link=".+?"\s+width="(\d+?)"\s?]]\s*\[\[span]](.+?)\[\[\/span]]\s*\[\[\/div]]/gi;

export default function preprocess(meta: PageMeta, input: string) {
  const parsedInput = input
    // Vote blocks
    .replace(/\[\[>]]\n\[\[module\sRate]]\n\[\[\/>]]/gi, '')

    // CSS blocks
    .replace(/\[\[module\sCSS]](.*?)\[\[\/module]]/sgi, '')
    .replace(/\[\[module\sCountPages.+?]](.*?)\[\[\/module]]/sgi, '')

    // Wiki links with title [[scp-173 | The Sculpture]]
    .replace(/\[\[\[(.+?)\s*\|(.+?)]]]/gi, '[$2](./$1)')

    // Wiki links without title [[SCP-173]]
    .replace(/\[\[\[([a-z_0-9#-]+)]]]/gi, '[$1](./$1)')

    // Italic
    .replace(/\/\/(.*?)\/\//gi, '*$1*')

    // HR
    .replace(/\n-+[\n$]/g, '\n\n----\n\n')

    // Strike
    .replace(/--(.+?)--/gi, '~~$1~~')

    // Footnotes
    .replace(/\[\[footnote]](.+?)\[\[\/footnote]]/gi, '^[$1]')

    // Monospace
    .replace(/{{(.+?)}}/gi, '`$1`')

    // Numbered lists
    .replace(/^# /mgi, '1. ')

    // Headers
    .replace(/^\+ /mgi, '# ')
    .replace(/^\+\+ /mgi, '## ')
    .replace(/^\+\+\+ /mgi, '### ')
    .replace(/^\+\+\+\+ /mgi, '#### ')
    .replace(/^\+\+\+\+\+ /mgi, '##### ')
    .replace(/^\+\+\+\+\+\+ /mgi, '###### ')

    // Collapsible
    .replace(/\[\[(\/)?collapsible(.*)?]]/gi, '\n[[$1collapsible$2]]\n')

    // Tabs
    .replace(/\[\[(\/)?tab(view)?]]/gi, '\n[[$1tab$2]]\n')

    // Code block [[code]] & [[/code]]
    .replace(/\n\[\[(\/)?code]]\n/gi, '\n```\n')

    .replace(imgBlockRegex, `::: image-right\n![$2]($1)\n$2\n:::`)

    .replace(imgLinkedRightRegex, `::: image-right\n![$3]($1)\n$3\n:::`)
    .replace(imgRightRegex, `::: image-right\n![$3]($1)\n$3\n:::`)
    .replace(imgCenterRegex, `::: image-center\n![$3]($1)\n$3\n:::`)
    .replace(imgLeftRegex, `::: image-left\n![$3]($1)\n$3\n:::`)

    // Simple inline images
    .replace(/\[\[=?image (.*?) style="(.*?)"]]/g, '![$1](./$1)')
    .replace(/\[\[=?image (.*?)]]/g, '![$1](./$1)')

    // Blank lines
    .replace(/@@@@\n/g, '<br />')

    // Center align
    .replace(/\[\[==]](.*?)\[\[\/==]]/sg, ':::align-center\n$1\n:::\n')

    // Dialogs
    .replace(/^-\s/mg, '— ');

    // Plugin for parsing tables
    return table(parsedInput);
}
