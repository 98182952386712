import renderMarkdown from './markdown';
import preprocess from './preprocess';
import postprocess from './postprocess';


export interface PageMeta {
  name: string;
}

export default function render(meta: PageMeta, source: string) {
  const preprocessed = preprocess(meta, source);
  const rendered = renderMarkdown(meta, preprocessed);
  const postprocessed = postprocess(meta, rendered);

  console.dir({meta, source, preprocessed, rendered, postprocessed});

  return postprocessed;
}
