import React from 'react';
import './MainPage.scss';


const MainPage: React.FC = () => (
    <div className="main-page">
        <div className="main-page__content">
            <div className="main-page__title">
                SCP Foundation Russia
            </div>
            <div className="main-page__welcome">
                Welcome to the public beta of SCP-RU wiki mobile version.
                We launch it as the first step in moving to our new platform that will replace Wikidot for our community.
                We've implemented most main parts of markup but there are still many places that are not working correctly.
                We'll deal with that problems one by one, slowly but steadily: some markup features will get support in
                our code and some of them will be replaced with simpler analogues.
                You can <a href="https://www.notion.so/SCP-Dev-Changelog-a6a572c9fd4e4df69631ed15df315ac9" target="_blank" rel="noopener noreferrer">track our progress on Notion</a>,
                as well as <a href="https://www.notion.so/What-is-this-about-7196dca338b143ff8d24626e27cc4e7c" target="_blank" rel="noopener noreferrer">read more about this project</a>.

                <br />
                <br />

                <div className="main-page__special-link-block">
                    <a href="/random">Go read something</a>
                </div>
            </div>
        </div>
    </div>
);

export default MainPage;
