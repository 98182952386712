
export default function (md: any) {
    md.core.ruler.push('tabs', (state: any) => {
        const TABVIEW_OPEN_REGEX = /\[\[tabview]]/;
        const TABVIEW_CLOSE_REGEX = /\[\[\/tabview]]/;
        const TAB_OPEN_REGEX = /\[\[tab\stitle="(.*)"]]/;
        const TAB_OPEN_REGEX_2 = /\[\[tab\s(.*)]]/;
        const TAB_CLOSE_REGEX = /\[\[\/tab]]/;
        const tokens = state.tokens;
        let i = 0;

        while (i < tokens.length - 1) {
            const openToken = tokens[i];
            const token = tokens[i+1];
            let match;

            // <Open>
            if (openToken.type === 'paragraph_open' &&
                token.content.match(TABVIEW_OPEN_REGEX)
            ) {
                const tabviewOpen = new state.Token('tabview_open', 'div', 0);
                tokens.splice(i, 3, tabviewOpen);
            }

            if (openToken.type === 'paragraph_open' &&
                (match = (
                    token.content.match(TAB_OPEN_REGEX)) ||
                    token.content.match(TAB_OPEN_REGEX_2)
                )
            ) {
                const tabOpen = new state.Token('tab_open', 'div', 0);

                tabOpen.content = match[1];
                tokens.splice(i, 3, tabOpen);
            }
            
            // <Close>
            if (openToken.type === 'paragraph_open' &&
                token.content.match(TABVIEW_CLOSE_REGEX)
            ) {
                const tabviewClose = new state.Token('tabview_close', 'div', 0);
                tokens.splice(i, 3, tabviewClose);
            }

            if (openToken.type === 'paragraph_open' &&
                token.content.match(TAB_CLOSE_REGEX)
            ) {
                const tabClose = new state.Token('tab_close', 'div', 0);
                tokens.splice(i, 3, tabClose);
            }

            i++;
        }
    });
};
