import React from 'react';
import Page from '../../components/Page/Page';
import { sdk } from '../../utils';
import { prepareTabsElements } from '../../components/tabs/tabs';
import render from '../../renderer';

const WIKI_NAME = 'scp-ru';
const DEFAULT_PAGE_NAME = 'scp-list';

class PageContainer extends React.Component<{ pageName: string }> {
    state: { page?: any } = {};

    componentDidUpdate(prevProps: any) {
        if (prevProps.pageName !== this.props.pageName) {
            this.loadPage(this.props.pageName || DEFAULT_PAGE_NAME).catch(console.error);
        }
        prepareTabsElements(document.body);
    }

    componentDidMount() {
        this.loadPage(this.props.pageName || DEFAULT_PAGE_NAME).catch(console.error);
    }

    async loadPage(pageName: string) {
        const result = await sdk.getWikidotPage(WIKI_NAME, pageName);
        const page = result.page;
        const renderedContent = render({ name: pageName }, page.content);

        document.title = `${page.title} – SCP Foundation`;
        this.setState({
            page: {
                title: page.title,
                html: renderedContent,
                rating: page.rating
            }
        });
    }

    render() {
        return <Page page={this.state.page} />;
    }
}

export default PageContainer;
