import React from 'react';
import { sdk } from '../../utils';
import { Redirect } from 'react-router';
import './RandomPage.scss';


interface RandomPageState {
    randomPageName?: string;
}

export default class RandomPage extends React.Component< {}, RandomPageState > {

    state: RandomPageState = {};

    componentDidMount() {
        sdk.getRandomWikidotPage().then(randomPageName => this.setState({ randomPageName }));
    }

    render() {
        if (this.state.randomPageName) {
            return <Redirect to={`/${this.state.randomPageName}`} />
        } else {
            return (
                <div className="random-page">
                    Loading random page
                </div>
            );
        }
    }
}
