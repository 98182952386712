import React from 'react';
import './Footer.scss';


const Footer: React.FC = () => (
    <footer className="footer">
        Unless otherwise stated, the content of this page is licensed under <a href="https://creativecommons.org/licenses/by-sa/3.0/" target="_blank" rel="noopener noreferrer">
            Creative Commons Attribution-ShareAlike 3.0 License
        </a>
    </footer>
);

export default Footer;
