import { PageMeta } from '.';

const linkTag = '<a class="link" href="$1">$2</a>';

const imgSrcRegex = /<img src="(?!http)/g;
const documentLinkRegex = /\[\[\[(document-.*)\s*\|(.*)]]]/g;

export default function postprocess(meta: PageMeta, input: string) {
  const imgSrc = `<img src="https://scp.storage.yandexcloud.net/wikidot/scp-ru/${meta.name.toLowerCase()}/`;

  return input
    .replace(/\s*_<br>\s*/g, ' ')

    // Colored text
    .replace(/##(\w+?)\|(.+?)##/g, '<span class="colored-text colored-text_$1">$2</span>')

    // Center text
    .replace(/\[\[=]](.*?)\[\[\/=]]/smg, '<div class="aligned-text aligned-text_center">$1</div>')

    .replace(imgSrcRegex, imgSrc)

    .replace(documentLinkRegex, linkTag)

    // Mdash - вызывает проблемы в урлах до картинок
    // .replace(/[^-]--[^-]/g, ' &mdash; ')

    .replace(/\[\[size (.*?)]](.*?)\[\[\/size]]/g, '<font size="$1">$2</font>')

    // Styled blocks
    .replace(/\[\[div style=&quot;.*?&quot;]](.*?)\[\[\/div]]/smg, '$1')
    .replace(/\[\[div class=&quot;.*?&quot;]]/g, '')
    .replace(/\[\[\/div]]/g, '')
    .replace(/\[\[span style=&quot;.*?&quot;]](.*?)\[\[\/span]]/smg, '$1');
}
