import './tabs.scss';

function createElementFromHTML(input: string) {
    var template = document.createElement('template');
    template.innerHTML = input.trim();
    return template.content.firstChild as ChildNode;
}

function generateId() {
    return Math.random().toString(36).substring(2);
}

export function prepareTabsElements(rootElement: HTMLElement) {
    Array.from(rootElement.querySelectorAll('.tabs')).forEach(element => {
        const idSequence: string[] = [];
        const getNextId = () => {
            const id = generateId();
            idSequence.push(id);
            return id;
        }

        const titleElements = Array.from(element.querySelectorAll('.tab__title'));
        const titles = titleElements.map(e => e.innerHTML);

        let titlesHtml = '<div class="tabs__titles">';
        for (let i = 0; i < titles.length; i++) {
            titlesHtml += `<div class="tabs__title${i === 0 ? ' tabs__title_active' : ''}" data-tab-id="${getNextId()}">${titles[i]}</div>`;
        }
        titlesHtml += '</div>';

        const titlesBlock = createElementFromHTML(titlesHtml);

        element.prepend(titlesBlock);
        titleElements.map(e => e.remove());

        Array.from(element.querySelectorAll('.tab')).forEach((tabElement, i) => {
            (tabElement as HTMLElement).dataset.tabContentId = idSequence[i];
            if (i === 0) {
                tabElement.classList.add('tab_active');
            }
        });
    });
}

export default function initTabs(element: HTMLElement) {
    element.addEventListener('click', (event) => {
        const target: any = event.target;

        if (target && Array.from(target.classList).includes('tabs__title')) {
            const tabsRoot = target.parentElement.parentElement;
            const tabId = target.dataset.tabId;

            const oldActiveTab = tabsRoot.querySelector('.tabs__title_active');
            if (oldActiveTab) {
                const oldTabId = oldActiveTab.dataset.tabId;
                tabsRoot.querySelector(`.tabs__title[data-tab-id="${oldTabId}"]`).classList.remove('tabs__title_active');
                tabsRoot.querySelector(`.tab[data-tab-content-id="${oldTabId}"]`).classList.remove('tab_active');
            }

            target.classList.add('tabs__title_active');
            tabsRoot.querySelector(`.tab[data-tab-content-id="${tabId}"]`).classList.add('tab_active');
        }
    });
}
