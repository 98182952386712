import markdown from 'markdown-it';
import { PageMeta } from '.';
import collapsible, { CloseTypes } from './plugins/collapsible';
import tabs from './plugins/tabs';

const md = markdown({
  breaks: true
})
  .use(require('markdown-it-sub'))
  .use(require('markdown-it-sup'))
  .use(require('markdown-it-footnote'))
  .use(require('markdown-it-deflist'))
  .use(require('markdown-it-abbr'))
  .use(require('markdown-it-anchor'))
  .use(require('markdown-it-table-of-contents'))
  .use(require('markdown-it-container'), 'image-right')
  .use(require('markdown-it-container'), 'align-center')
  .use(collapsible)
  .use(tabs);

export default function renderMarkdown(meta: PageMeta, content: string) {
  md.renderer.rules.collapsible_close = (tokens, index) => {
    const content: any = tokens[index].content;

    return content.closeType === CloseTypes.top ?
      '</div></div>' :
      `</div><div class="collapsible__close">${content.closeText}</div></div>`;
  };
  md.renderer.rules.collapsible_open = (tokens, index) => {
    const content: any = tokens[index].content;
    const closeType = content.closeType;
    let collapsibleBlock = `<div class="collapsible"><div class="collapsible__open">${content.openText}</div>`;

    if (closeType === CloseTypes.top || closeType === CloseTypes.both) {
      collapsibleBlock += `<div class="collapsible__close">${content.closeText}</div>`;
    }

    return collapsibleBlock + '<div class="collapsible__content">';
  };

  md.renderer.rules.tabview_open = () => '<div class="tabs">';
  md.renderer.rules.tabview_close = () => '</div>';
  md.renderer.rules.tab_close = () => '</div></div>';
  md.renderer.rules.tab_open = (tokens, index) => {
    const title: string = tokens[index].content;

    return `<div class="tab"><div class="tab__title">${title}</div><div class="tab__content">`;
  };

  return md.render(content);
}
