import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App/App';
import * as serviceWorker from './service-worker';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import RandomPage from './containers/RandomPage/RandomPage';
import initCollapsibles from './components/collapsible/collapsible';
import initTabs from './components/tabs/tabs';


document.addEventListener("DOMContentLoaded", () => {
    ReactDOM.render((
        <Router>
            <Switch>
                <Route path="/random" component={RandomPage} />
                <Route path="/:pageName" component={App} />
                <Route path="/" component={App} />
            </Switch>
        </Router>
    ), document.getElementById('root'));

    initCollapsibles(document.body);
    initTabs(document.body);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
